import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FormControl,
  Grid,
  LinearProgress,
  Select,
  MenuItem, InputLabel, OutlinedInput, TextField, Button, Typography
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import {
  apiGetPlayer,
  PlaylistState
} from '../../store/playlistSlice';
import { ReactComponent as PencilIcon } from '../../icons/pencil.svg';
import { OrganisationContext } from '../../App';
import { useParams } from 'react-router-dom';
import { RootState } from '../../index';
import axios from 'axios';
import { getApiBaseUrl } from '../../utils/env';
import Table from '@mui/material/Table';
import { TableCellBody, TableCellHead, TableRow } from '../common/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import removeButton from '../../icons/remove.png';
import { Player } from '../types/playlist';
import PlaylistRoot from './PlaylistRoot';

const playerName = ['pixels', 'pixels-no-ads', 'pixels-float', 'outstream', 'pixels-demo-companion-ads'];

export default function PlaylistPreview() {
  const { playlist } = useParams();
  const dispatch = useDispatch();
  const { selectedOrganisation } = useContext(OrganisationContext);
  const [id, setId] = useState<number | null>(null);
  const [player, setPlayer] = useState<string>(playerName[0]);
  const [name, setName] = useState<string>('');
  const [adUnit, setAdUnit] = useState<string>('');

  const {
    busy,
    currentPlaylist,
    players,
  } = useSelector<RootState, PlaylistState>((state) => state.playlist);

  const reset = () => {
    setName('');
    setPlayer(playerName[0]);
    setAdUnit('');
    setId(null);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (id) {
      await axios
        .put(`${getApiBaseUrl()}/playlist/${selectedOrganisation}/${playlist}/player/${id}`, {
          player,
          playlistId: playlist,
          name,
          adUnit
        });
    } else {
      await axios
        .post(`${getApiBaseUrl()}/playlist/${selectedOrganisation}/${playlist}/player`, {
          player,
          playlistId: playlist,
          name,
          adUnit
        });
    }

    reset();

    dispatch(apiGetPlayer({
      publisher: selectedOrganisation,
      playlistId: playlist
    }));
  };

  const onRemove = async (id: number) => {
    await axios
      .delete(`${getApiBaseUrl()}/playlist/${selectedOrganisation}/${playlist}/player/${id}`);
    dispatch(apiGetPlayer({
      publisher: selectedOrganisation,
      playlistId: playlist
    }));
  };

  const onEditPlayer = ({ name, ad_unit, player, id }: Player) => {
    setId(id);
    setAdUnit(ad_unit);
    setPlayer(player);
    setAdUnit(ad_unit);
    setName(name);
  };

  useEffect(() => {
    if (!selectedOrganisation) return;
    dispatch(apiGetPlayer({
      publisher: selectedOrganisation,
      playlistId: playlist
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrganisation]);

  if (!currentPlaylist) {
    return <p>Loading..</p>;
  }

  return (
    <PlaylistRoot>
      {currentPlaylist.format === 'vertical' && <Grid container spacing={2}>
        <Grid item xs={6}>
          <h3>{id ? 'Edit' : 'Create'} preview player</h3>
          <form onSubmit={onSubmit}>
            <Grid container spacing={2} style={{ marginTop: 0 }}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    label="Name"
                    variant="outlined"
                    type={'text'}
                    required
                    id="playerName"
                    value={name}
                    onChange={({ target: { value } }) => setName(value)}/>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    label="Ad unit"
                    variant="outlined"
                    type={'text'}
                    id="adUnit"
                    value={adUnit}
                    onChange={({ target: { value } }) => setAdUnit(value)}/>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="compPlayerLabel">Player</InputLabel>
                  <Select
                    id="compPlayer"
                    required
                    labelId="compPlayerLabel"
                    value={player}
                    onChange={({ target: { value } }) => setPlayer(value)}
                    input={<OutlinedInput label="Player"/>}
                  >{playerName.map(playerType =>
                    (<MenuItem value={playerType} key={playerType}>{playerType}</MenuItem>))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent={'space-between'}>
                  <Grid item>
                    <Button color="inherit" variant="contained" onClick={reset}
                            type={'button'}>Reset</Button>
                  </Grid>
                  <Grid item>
                    <Button color="primary" variant="contained" type={'submit'}>Save</Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
        <Grid item xs={6}>
          <h3>Players:</h3>
          {players.length > 0 && currentPlaylist && <Table size="small">
            <TableHead>
              <TableRow>
                <TableCellHead>NAME</TableCellHead>
                <TableCellHead>AD UNIT</TableCellHead>
                <TableCellHead>PLAYER TYPE</TableCellHead>
                <TableCellHead></TableCellHead>
              </TableRow>
            </TableHead>
            <TableBody>
              {players.map((row) => (<TableRow key={row.id}>
                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                <TableCellBody><a target="_blank" rel="noopener"
                                  href={`https://shorts.pixels.ai/build/${row.player}/dmpu.html?playlistId=${row.playlist_uuid}&organisation=${currentPlaylist.organisation}&adUnit=${row.ad_unit}`}>{row.name}</a></TableCellBody>
                <TableCellBody>{row.ad_unit}</TableCellBody>
                <TableCellBody>{row.player}</TableCellBody>
                <TableCellBody align="center">
                  <Grid container justifyContent={'space-around'} spacing={1}>
                    <Grid item>
                      <PencilIcon style={{ cursor: 'pointer' }} onClick={() => onEditPlayer(row)}/>
                    </Grid>
                    <Grid item>
                      <span onClick={async () => {
                        if (!window.confirm(`Are you sure you want to remove '${row.name}'?`)) {
                          return;
                        }
                        await onRemove(row.id);
                      }} style={{ cursor: 'pointer' }}><img
                        width={20}
                        src={removeButton}
                        alt="Remove player"/></span>
                    </Grid>
                  </Grid>
                </TableCellBody>
              </TableRow>))}
            </TableBody>
          </Table>
          }
          {players.length === 0 && <p>This playlist has no player yet.</p>}
        </Grid>
      </Grid>}
      {currentPlaylist.format === 'horizontal' &&
        <Typography sx={{ marginTop: '20px' }} variant={'body1'}>Preview is only available for
          vertical video.</Typography>}
      {busy && <LinearProgress color="success"/>}
    </PlaylistRoot>
  );
}
